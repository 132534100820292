/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  CTA buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  Text colors
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #545454 !important;
}

.text-theme-secondary {
  color: #0375a3 !important;
}

.text-theme-success {
  color: #44b649 !important;
}

.text-theme-fh {
  color: #f20808 !important;
}

.text-theme-fm {
  color: #0375a3 !important;
}

.text-theme-fa {
  color: #934a9c !important;
}

.text-theme-as {
  color: #37beed !important;
}

.text-theme-haccp {
  color: #209044 !important;
}

/* ----------------------------------------------------------------------------
  Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #545454 !important;
}

.bg-theme-fh {
  background-color: #f20808 !important;
}

.bg-theme-fm {
  background-color: #0375a3 !important;
}

.bg-theme-fa {
  background-color: #934a9c !important;
}

.bg-theme-as {
  background-color: #37beed !important;
}

.bg-theme-haccp {
  background-color: #209044 !important;
}

.bg-btn-disabled {
  background-color: #e9ecef !important;
}

.bg-theme-upsells {
  background-color: #e7f2f6 !important;
}

.bg-theme-light-blue {
  background-color: #f3fbff;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
  cursor: grab;
}
.tns-ovh:active {
  cursor: grabbing;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.tns-nav {
  text-align: center;
  margin: 10px 0;
}
.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}
.tns-nav > .tns-nav-active {
  background: #999;
}

.tns-controls {
  text-align: center;
}
.tns-controls > [aria-controls] {
  color: #545454;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0 8px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid inherit;
}
.tns-controls > [aria-controls]:hover, .tns-controls > [aria-controls]:active {
  color: #fff;
  background-color: #545454;
}
.tns-controls > [aria-controls][disabled] {
  opacity: 0.35;
  cursor: not-allowed;
}
.tns-controls > [aria-controls][disabled]:hover {
  color: #545454;
  background-color: transparent;
}
.tns-controls > button {
  border: 1px solid #545454;
}

#reviews-carousel .tns-controls button {
  position: absolute;
  top: calc(50% - 20px);
  background: #e9e9e9;
  border: 3px solid #fff;
  color: #999;
  transition: 0.3s;
  line-height: 40px;
}
#reviews-carousel .tns-controls button:hover {
  background: #545454;
  color: #fff;
}
#reviews-carousel .tns-controls button[data-controls=prev] {
  left: -30px;
}
#reviews-carousel .tns-controls button[data-controls=next] {
  right: -30px;
}
#reviews-carousel .tns-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}
#reviews-carousel .tns-nav button {
  transition: 0.3s;
}
#reviews-carousel .tns-nav button.tns-nav-active {
  transform: scale(1.6);
}

#compliance-carousel .food-handler-legend .legend-text:before,
#compliance-carousel .food-manager-legend .legend-text:before {
  content: "";
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 3px;
}
#compliance-carousel .food-handler-legend .legend-text.accepted-statewide:before {
  background-color: #ef1c1d;
}
#compliance-carousel .food-handler-legend .legend-text.accepted-most-areas:before {
  background-color: #e99a9b;
}
#compliance-carousel .food-handler-legend .legend-text.not-accepted:before {
  background-color: #cdcdcd;
}
#compliance-carousel .food-manager-legend .legend-text.accepted-statewide:before {
  background-color: #1f71a0;
}
#compliance-carousel .food-manager-legend .legend-text.accepted-most-areas:before {
  background-color: #62cfec;
}
#compliance-carousel .food-manager-legend .legend-text.not-accepted:before {
  background-color: #cdcdcd;
}
#compliance-carousel .tns-nav button {
  transition: 0.3s;
}
#compliance-carousel .tns-nav button.tns-nav-active {
  transform: scale(1.6);
}
#compliance-carousel .tns-nav button:first-of-type {
  background-color: #ef1c1d;
}
#compliance-carousel .tns-nav button:last-of-type {
  background-color: #1f71a0;
}